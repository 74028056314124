/* NewsPage.css */

.news-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.news-post {
  margin-bottom: 40px;
  padding: 20px;
  border: 1px solid var(--borderColors);
  border-radius: 10px;
  background-color: var(--cardBackgroundColor);
}

.news-title {
  font-size: 28px;
  margin-bottom: 10px;
  color: var(--fontColor);
}

.news-date {
  font-size: 14px;
  color: var(--fontDarkColor);
  margin-bottom: 20px;
}

.news-content {
  font-size: 16px;
  line-height: 1.6;
}

.news-content p {
  margin-bottom: 1em;
}

.news-content a {
  color: #0073aa;
  text-decoration: none;
  word-break: break-word; /* Ensures URLs break at word boundaries */
  overflow-wrap: break-word; /* Adds additional support for legacy browsers */
}

.news-content a:hover {
  text-decoration: underline;
}

.news-content img,
.news-content video {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
}

.news-content hr {
  border: 1px solid #ddd;
  margin: 40px 0;
}

@media (max-width: 600px) {
  .news-title {
    font-size: 24px;
  }

  .news-content {
    font-size: 14px;
  }
}
