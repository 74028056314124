.bulletText {
  padding-top: 3px;
}

.checkIconColored {
  color: var(--primaryColor);
}

.subscriptionCard {
  height: 500px;
  padding-top: 10px;
  position: relative;
  overflow: hidden; /* Ensure the ribbon does not overflow */
}

.subscriptionBox {
  border-radius: 12px;
  padding: 10px;
  height: 80%;
  align-items: left;
  justify-content: left;
}

.selectedCard {
  background-color: var(--backgroundSubscriptionCard);
}

.priceCard {
  width: 150px;
  height: 50px;
  padding: 8px;
  margin: 6px;
}

.checklist {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 10px;
  margin: auto;
  height: auto;
}

.checklist .MuiTypography-root {
  display: flex;
  align-items: flex-start;
}

.ribbon {
  display: block;
  width: 200px;
  position: absolute;
  top: 30px;
  right: -55px;
}

.ribbon span {
  display: block;
  background-color: var(--primaryColor);
  color: white;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
}
