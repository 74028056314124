#lastItemDot {
  display: none;
}
.caret-down-45 span.icon svg {
  transform: rotate(45deg);
  margin-top: 2px;
}
.caret-without-content span.icon {
  display: none;
}
.caret-without-content-outer .icon {
  display: none;
}

.caret-without-content-outer #lastItemDot {
  display: block;
  font-size: 26px;
  color: var(--fontColor);
  margin-top: 4px;
}

.caret-without-content #lastItemDot {
  display: block;
  font-size: 26px;
  color: var(--fontColor);
  /* margin-top: 6px;
  margin-right: 8px;
  margin-left: 23px; */
}
.caret-without-content {
  color: black;
  align-items: center;
}

.listViewParent {
  background-color: var(--BackgroundColor);
}
.listViewParent svg:hover {
  background: lightgray;
  border-radius: 33px;
}
.listViewParent span {
  user-select: text;
  /* cursor: text; */
}
.ideacardDiv {
  display: flex;
  gap: 7px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0px 8px;
  padding-top: 4px;
  padding-bottom: 2px;
  cursor: pointer;
}
.ideacardDiv span {
  cursor: pointer;
}

.highlightDiv {
  display: flex;
  gap: 12px;
  margin-left: 6.5px;
  align-items: flex-start;
}
.customCursor {
  cursor: url("../../../public/Assets/svg_marker/MarkerWithPointer_xs_3.svg"),
    auto !important;
}

.highlightDiv .icon {
  display: inline-block;
  font-size: 0.5rem;
  margin-top: 8px;
}

#lastItemDot:hover {
  background: transparent;
}

.tagListWrapper > div {
  margin-top: 0px !important;
}

:root {
  --topTransitionSpeed: 0.7s;
  --shrinkTransitionSpeed: 0.2s;
}

.tileViewWrapper,
.listViewWrapper {
  height: calc(100vh - 145px);
  transition: height var(--shrinkTransitionSpeed) linear; /* faster transition so scroll bar wont show when resizing the content and resizing looks smoother */
}

.tileViewWrapper.grow,
.listViewWrapper.grow {
  transition: height var(--topTransitionSpeed) linear; /* slower transition so scroll bar wont show when resizing the content and resizing looks smoother */
}

.highlightSync {
  transition: top var(--shrinkTransitionSpeed); /* faster transition for smoother up/down slide  */
}

.highlightSync.up {
  transition: top 10s; /* slow transition for smoother up/down slide */
}

.topTransition {
  position: relative;
  top: 0; /* Stay on top */
  width: 100%; /* Full width */
  transition: top var(--topTransitionSpeed); /* faster transition so scroll bar wont show when resizing the content and resizing looks smoother */
}

@media screen and (max-width: 899px) {
  .tileViewWrapper,
  .listViewWrapper {
    height: calc(100vh - 195px);
  }
}
