/* input type radio style */
.form-control {
  position: relative;
}
.filter-radio{
    padding-right: 5px;
}
.filter-radio [type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.filter-radio [type="radio"]:checked + label,
.filter-radio [type="radio"]:not(:checked) + label {
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}
.filter-radio [type="radio"]:checked + label:before,
.filter-radio [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 100%;
}

.filter-radio [type="radio"]:checked + label:after,
.filter-radio [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  top: 7px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.filter-radio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.filter-radio [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
/* input type checkbox style */

.filter-checkbox.filter-checkbox{
    margin-right: 6px;
}

.filter-checkbox-no-margin.filter-checkbox {
    margin-right: 0px;
}

.filter-checkbox.form-control input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .filter-checkbox.form-control label {
    position: relative;
    cursor: pointer;
  }
  
  .filter-checkbox.form-control label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    width: 17px;
    height: 17px;
    border-radius: 5px;
  }
  
  .filter-checkbox.form-control input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
