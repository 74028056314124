.dotsWrapper {
  position: relative;
  padding-left: 20px;
}
.dotsWrapper::after {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  top: 10px;
  background: var(--fontLightColor);
  border-radius: 50%;
  left: 8px;
}
.bottom-line.dotsWrapper::after{
  top: 8px;
}
.imageStyle {
  border: 1px solid var(--borderColors);
  border-radius: 20%;
  max-width: 50px;
  max-height: 50px;
}

.titleStyle {
  color: var(--fontLightColor);
  font-size: var(--font-regular);
  font-weight: 600;
  @media screen and (min-width: 600px) { 
    font-size: var(--font-large);
  }
}

.itemTitleStyle {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: var(--font-primary);
  font-weight: 500;
  color: var(--fontDarkColor);
  @media screen and (min-width: 600px) {    
    padding: 0 44px;
    font-size: var(--font-largest);
  }
}