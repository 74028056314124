 .link_Modified {
  display: flex;
  gap: 4px;
  padding: 3px 10px;
  --fill: 0;
  --weight: 400;
  border: none;
  cursor: pointer;
  align-items: center;
  /* margin: 19px 0px; */
  /* transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045); */
  border-radius: 24px;
}

.radioInputs .link_Modified {
  display: flex;
  gap: 4px;
  padding: 3px 10px;
  --fill: 0;
  --weight: 400;
  --iconFontSize: 21px;
  border: none;
  cursor: pointer;
  align-items: center;
  margin: 5px 0px;
  font-size: 13px;
}

.activeState_Modified {
  display: flex;
  color: var(--fontColor);
  gap: 4px;
  padding: 3px 10px;
  border: none;
  cursor: pointer;
  align-items: center;
  /* margin: 19px 0px; */
  /* background: var(--ClickState); */
  --fill: 1;
  --weight: 900;
  border-radius: 24px;
  /* width: 185px;   */
}
.radioInputs .activeState_Modified {
  display: flex;
  /* color: var(--fontColor); */
  gap: 4px;
  padding: 3px 10px;
  border: none;
  cursor: pointer;
  align-items: center;
  margin: 5px 0px;
  /* background: var(--ClickState); */
  --fill: 1;
  --iconFontSize: 21px;
  --weight: 900;
  border-radius: 24px;
  font-size: 13px;
  width: 151px;
}

.ellipsis_Style {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.radio .MuiFormControlLabel-root {
  margin-right: 10px !important;
}
.radio .MuiButtonBase-root {
  padding: 5px !important;
}
.radio .MuiSvgIcon-root,
.checkbox .MuiSvgIcon-root {
  font-size: 20px !important;
  color: var(--fontColor) !important;
}
.MuiButtonBase-root MuiCheckbox-root {
  padding: 5px 10px !important;
  padding-right: 5px !important;
}
.checkbox .css-1jaw3da,
.checkbox .css-j204z7-MuiFormControlLabel-root ,.checkbox .MuiFormControlLabel-root{
  margin-right: 1px !important;
}

.filter-listing::-webkit-scrollbar {
  background: transparent;
}
.filter-listing::-webkit-scrollbar {
  width: 0px;
}

.viewMenuSpacing {
  gap: 0.75rem;
  margin-top: 9px;
}

.filterTitle {
  font-weight: 600;
  font-size: var(--font-regular);
  color: var(--fontColor);
  margin-left: 5px;
}

.filterTitle.disabled {
  color: var(--disableColor);
}