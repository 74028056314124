.markdown {
  margin: 10px;
}
.markdown h1 {
  font-weight: 600;
  font-size: var(--font-primary);
}
.markdown h1~p {
  padding-left: 5px;
  font-style: italic;
}
.markdown h2 {
  padding-left: 5px;
  font-size: var(--font-primary);
  font-weight: 600;
}
.markdown h2~p {
  padding-left: 10px;
  font-style: italic;
}
.markdown h3 {
  padding-left: 10px;
  font-size: var(--font-regular);
  font-weight: 600;
}
.markdown h3~p {
  padding-left: 15px;
  font-size: var(--font-regular);
  font-style: italic;
}
.markdown h4 {
  padding-left: 15px;
  font-size: var(--font-regular);
}
.markdown h4~p {
  padding-left: 20px;
  font-size: var(--font-regular);
  font-style: italic;
}
.markdown h5 {
  padding-left: 20px;
  font-size: var(--font-regular);
}
.markdown h5~p {
  padding-left: 25px;
  font-size: var(--font-regular);
  font-style: italic;
}
.markdown h6 {
  padding-left: 25px;
  font-size: var(--font-regular);
}
.markdown h6~p {
  padding-left: 30px;
  font-size: var(--font-regular);
  font-style: italic;
}
.markdown ul {
  padding-left: 40px;
  font-size: var(--font-regular);
}
.markdown li {
  list-style: square;
}
.markdown li li {
  list-style: circle;
}
.markdown li li li {
  list-style: square;
}
.markdown-button {
  position: relative;
  float: right;
  border-radius: 30px;
  background-color: var(--selectedBackgroundColor);
  color: var(--fontColor);
  padding: 0px 10px;
  margin: 10px 10px 10px 0px;
}
.markdown table, th {
  border: 1px solid;
  padding: 4px;
}
.markdown td {
  border-left: 1px solid;
  border-right: 1px solid;
  padding: 4px;
}