.libraryLists {
  width: 100%;
  background: transparent;
  justify-content: space-between;
  /* gap: 13px; */
  padding: 0 0.7rem;
}
.fetchStatusIconContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 0;
}
.libraryListsContainer {
  /* margin: 11px 0px; */
  position: relative;
  margin-top: 0;
  border: 1px solid var(--borderColors);
  border-radius: var(--cardBorderRadius);
  background: var(--cardBackgroundColor);
  padding: 0.7rem;
  color: var(--fontColor);
  border-color: var(--borderColors);
  margin-bottom: 10px;
  justify-content: start;
}

.bookViewContainer {
  height: calc(100vh - 155px);
  overflow: hidden;
}

@media screen and (max-width: 899px) {
  .bookViewContainer {
    height: calc(100vh - 191px);
  }
}
.bookViewContainer {
  height: calc(100vh - 155px);
  overflow: hidden;
}
@media screen and (max-width: 899px) {
  .bookViewContainer {
    height: calc(100vh - 191px);
  }
  .ideacardParentContainer {
    height: calc(100vh - 58px);
  }
}

.highlightBook {
  background: var(--cardHighlightBackgroundColor);
}
.libraryListsImg {
  @media screen and (min-width: 600px) {
    width: 123px;
    height: 190px;
  }
  @media screen and (max-width: 600px) {
    width: 90px;
    height: 121px;
  }
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
}
.feedListsImg {
  width: 12px;
  height: 19px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
}
.dots {
  display: inline-block;
}
.container {
  position: relative;
  display: inline-block;
}

.myNotesInput {
  background-color: var(--cardBackgroundColor);
  color: var(--fontDarkColor);
}

.myNotesInput::placeholder {
  font-size: 21px;
}
.ideacard-Title {
  /* background: white; */
  /*z-index: 11;*/
  padding-top: 0.5rem;
  border-radius: 12px;
}
.ideacardParentContainer {
  border: 1px solid var(--borderColors);
  background: var(--cardBackgroundColor);
  padding: 0px 0px 0.5rem;
  border-radius: 12px;
  /* background: white; */
  margin: 0px 0px 4.1rem 0.1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 155px);
}
@media screen and (max-width: 899px) {
  .ideacardParentContainer {
    height: calc(100vh - 64px);
  }
}
.ideacardTopRowContainer {
  display: flex;
  justify-content: space-between;
  padding-right: 55px;
  align-items: center;
}

.editWrapper {
  background: var(--white);
  border-radius: 33px;
  border: 1px solid var(--borderColors);
  position: absolute;
  /* right: 1px; */
  cursor: pointer;
  color: var(--fontColor);
  padding: 1px;
}
.editWrapper:first-of-type svg {
  padding: 1px;
}

.googleImgSelectWrapper .editWrapperOuter {
  display: none;
  height: 100%;
}
.googleImgSelectWrapper:hover .editWrapperOuter {
  display: block;
  background: transparent;
  z-index: 9999999;
  width: 100%;
  height: calc(100% - 130px) !important;
  height: 100px;
  position: absolute;
}
.highlightSpan::selection {
  background-color: var(--primaryColor);
  color: var(--white);
}

.tabWrapper .MuiTabs-flexContainer button:first-of-type {
  padding-left: 0px !important;
  margin-left: 0px !important;
}
.tabWrapper .MuiTabs-flexContainer button:last-child {
  padding-right: 0px !important;
  margin-right: 0px !important;
}
.tabWrapper.disabled_first > div > div > div > div > div button:nth-child(1) {
  color: #d7d7d7 !important;
}
.tabWrapper.disabled_second > div > div > div > div > div button:nth-child(2) {
  color: #d7d7d7 !important;
}
.tabWrapper.disabled_fifth > div > div > div > div > div button:nth-child(5) {
  color: #d7d7d7 !important;
}
.tabWrapper.disabled_third > div > div > div > div > div button:nth-child(3) {
  color: #d7d7d7 !important;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.alert {
  color: var(--alertColor);
  font-size: var(--font-regular);
}

.alertIcon {
  color: var(--alertColor);
  padding-bottom: 3px;
  padding-right: 4px;
}

.iconStyle {
  color: var(--fontColor);
  font-size: var(--font-smaller);
}

.iconTopContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.deleteHighlight:hover {
  background-color: var(--cardHighlightBackgroundColor);
  border-radius: 15px;
  cursor: url("../../../public/Assets/delete_cursor.png"), auto;
}

.multi-line-ellipsis {
  display: -webkit-box; /* Use the WebKit box model */
  -webkit-box-orient: vertical; /* Orient the box vertically */
  -webkit-line-clamp: 3; /* Limit the text to 3 lines */
  line-clamp: 3; /* Limit the text to 3 lines */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Show ellipsis (...) for overflow */
  line-height: 1.5em; /* Set line height */
  max-height: 4.5em; /* Max height for 3 lines */
  white-space: normal; /* Allow normal wrapping */
  word-wrap: break-word; /* Break long words if necessary */
}

@media screen and (max-width: 400px) {
  .multi-line-ellipsis {
    -webkit-line-clamp: 2; /* Limit the text to 2 lines */
    line-clamp: 2; /* Limit the text to 2 lines */
    max-height: 3em; /* Max height for 2 lines */
  }
}
