.blurred-background-dark {
  background: -webkit-linear-gradient(
    #d3caca,
    #e5dada,
    #0e0c0c 100%,
    #000 100%
  ); /* dark */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blurred-background-light {
  background: -webkit-linear-gradient(
    #333,
    #333,
    #eee 100%,
    #eee 100%
  ); /* light */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.lineWrap {
  white-space: pre-wrap;
}
