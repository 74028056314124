@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", */
  /* "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style: none;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}

.toast-container a {
  color: blue;
  text-decoration: underline;
}

.feedParentContainer {
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  overflow: auto;
  height: 100vh;
  @media screen and (max-width: 600px) {
    padding-left: 3px;
    padding-right: 3px;
    padding-bottom: 56px;
  }
  color: var(--fontColor);
}

.feedBoxLayout {
  width: 38%;
  height: 100%;
}

.messageBoxLayout {
  width: 58%;
  height: 100%;
  position: absolute;
  top: 20%;
  left: 20%;
}

.flexbox {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.flexbox .content {
  flex: 1 1 auto;
  margin: 10px 0;
}

.readmore {
  padding-bottom: 50px;
  position: relative;
  overflow: hidden;
  transition: height 0.7s ease;
}

.readmore-link {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 60px;
  text-align: center;
  padding-top: 31px;
  cursor: pointer;
}
.readmore-link.expand {
  position: relative;
  background-image: none;
  padding-top: 10px;
  height: 20px;
  background: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.feedWrapperOverflow::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.feedWrapperOverflow {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media screen and (max-width: 2200px) {
  .feedBoxLayout {
    width: 43%;
  }
}

@media screen and (max-width: 1800px) {
  .feedBoxLayout {
    width: 48%;
  }
}
@media screen and (max-width: 1600px) {
  .feedBoxLayout {
    width: 58%;
  }
}
@media screen and (max-width: 1450px) {
  .feedBoxLayout {
    width: 68%;
  }
}

@media screen and (max-width: 1250px) {
  .feedBoxLayout {
    width: 78%;
  }
}

@media screen and (max-width: 950px) {
  .feedBoxLayout {
    width: 88%;
  }
}

@media screen and (max-width: 750px) {
  .feedBoxLayout {
    width: 100%;
  }
}
