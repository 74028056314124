.searchResult {
  display: flex;
  align-items: center;
  margin-top: 12px;
  justify-content: center;
  /* padding-left: 3.6rem;
  padding-right: 0.5rem; */
}
.searchResult span {
  background-color: white;
  padding: 0 5px;
}
.searchResult span:first-of-type {
  border-radius: 2px 0 0 2px;
}
.searchResult span:last-child {
  border-radius: 0 2px 2px 0;
}
.searchInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.infoText {
  font-size: 14px;
  font-weight: bold;
  color: #a6a6a6;
}

.uploadContainer button {
  display: flex;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: var(--ClickState);
  margin: 0 auto;
}
.uploadContainer > button:hover {
  background: var(--ClickState);
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.suggestionWrapper {
  z-index: 2;
}
.custom-file-upload button svg {
  background-color: transparent;
}
.custom-file-upload button {
  background-color: white;
}
.custom-file-upload input[type="file"] {
  width: 100%;
  max-width: 480px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -85%);
  opacity: 0;
}
.custom-upload-file-active input[type="file"] {
  width: 100% !important;
}
.custom-file-upload .active svg {
  color: var(--primaryColor);
}
