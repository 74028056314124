.DrawerModalParent {
  display: flex;
  flex-flow: column;
  height: 100vh;
  border-left: 1px solid;
  border-top-right-radius: 23px;
  border-bottom-right-radius: 23px;
  color: var(--fontColor);
  border-color: var(--borderColors);
  background-color: var(--submenuBackgroundColor);
  box-shadow: 10px 10px 10px var(--shadowColor);
}

.DrawerModalParent .headingSection {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--borderColors);
  padding: 15px 11px 15px 18px;
}
.DrawerModalParent .headingSection #heading {
  font-size: var(--fontSizeHeading);
}
.DrawerModalParent .contentsection {
  padding: 15px 11px 80px 18px;
  overflow-y: auto;
  overflow-x: hidden;
}
/* .material-symbols-outlined
{
    font-size: 25px;
} */
