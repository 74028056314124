.feedDetailsCard {
  border-width: 1px;
  /* max-width: 710px; */
  border-color: var(--borderColors);
  background-color: var(--cardBackgroundColor);
  color: var(--fontColor);
  border-radius: 12px;
  padding: 24px 16px;
  gap: 12px;
  margin: 3px;
  margin-bottom: 20px;
  justify-content: start;
}

.suggestion {
  border-color: var(--primaryColor);
}
