:root {
  --fill: 0;
  --weight: 400;
  --cardBorderRadius: 12px;
  --iconFontSize: 23px;
  /* __________________________________colors start__________________________________ */
  --white: #ffffff !important;
  --dark: #000000;
  --fontColor: #717171;
  --ClickState: #e3e3e3 !important;
  --BackgroundColor: #f9f9f9;
  --borderColors: #e5e4e4;
  --greyColor: grey;
  --primaryColor: #ff6600;
  --primaryColorHover: #ff9900;
  --cardBackgroundColor: #fff;
  --cardHighlightBackgroundColor: #fff9f5;
  --cardBorderRadius: 12px;
  --backgroundBlackFirst: #2c2f31; /* App level or main component level */
  --backgroundBlackSecond: #1f1f1f; /* Used in particular component with box layout like feed library.  */
  --backgroundBlackThird: #333d4e !important; /* To show active links or button, like in navbar and filter in sidebar. */
  --alertColor: orangered;
  /* __________________________________colors end__________________________________ */

  /* ________________________________font size start__________________________________ */
  --font-smallest: 10px;
  --font-smaller: 12px;
  --font-regular: 14px;
  --font-primary: 16px;
  --font-large: 18px;
  --font-largest: 22px;
  --fontSizeHeading: 21px;
  /* _________________________________font size end__________________________________ */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}
.rootContainer {
  display: flex;
  background-color: var(--BackgroundColor);
}
.material-symbols-outlined {
  font-size: var(--iconFontSize);
}
.main-container {
  display: flex;
}
main {
  padding: 10px;
  width: 100% !important;
}

.logoColapse {
  width: 300px;
  /* height: 300px; */
}
.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: baseline;
  background-color: var(--cardBackgroundColor);
  color: var(--fontColor);
}

.sidbarItem {
  background-color: var(--selectedBackgroundColor);
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 10px 0px 10px;
}
.logo {
  font-size: 18px;
  line-height: 0;
}

.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);

  color: var(--BackgroundColor);
}

.routes {
  /* margin-top: 15px; */
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 180px;
}

.mainmenu {
  /* margin-top: 10px; */
  margin-left: 3px;
}
.routesCollapsible {
  /* margin-top: 15px; */
  display: flex;
  flex-direction: column;
  width: 53px;
}
.link {
  display: flex;
  gap: 19px;
  padding: 5px 10px;
  --fill: 0;
  --weight: 400;
  border: none;
  cursor: pointer;
  align-items: center;
  margin: 1px 0px;
  border-radius: 24px;
  width: 180px;
  /* height: 56px; */
  /* transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045); */
}
.linkCollapsible {
  display: flex;
  flex-flow: column;
  color: var(--fontColor);
  background: var(--cardBackgroundColor);
  align-items: center;
  gap: 1px;
  /* padding: 6px 4px; */
  --fill: 0;
  --weight: 400;
  border: none;
  cursor: pointer;
  margin: 1.5px 0px;
  border-radius: 9px;
  height: 44px;
  width: 53px;
  /* transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045); */
}
.linkCollapsible:hover {
  border: none;
  background: var(--HoverOver);
  border-radius: 9px;
  --fill: 0;
  /* --weight: 900; */
  /* transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045); */
}
.disable,
.disable:hover {
  background-color: transparent !important;
  cursor: auto;
  color: var(--disableColor) !important;
}
.disable > div,
.disable > div > svg,
.disable:hover > div,
.disable:hover > div > svg {
  background-color: transparent !important;
  cursor: auto;
  color: var(--disableColor) !important;
  pointer-events: none;
}
.selectBook:hover {
  cursor: auto;
}
.selectBook:active {
  pointer-events: none;
  cursor: auto;
}

.link:hover {
  border: none;
  background: var(--HoverOver);
  border-radius: 24px;
  --fill: 0;
  /* --weight: 900; */
  /* transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045); */
}

/* .link:active {
  border: none;
  background: var(--ClickState);
  --fill: 1;
  --weight: 900;
  border-radius: 24px;
  transform: scale(0.98);
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.24);
} */
#activeMainMenu {
  border: none;
  background: var(--selectedBackgroundColor);
  --fill: 1;
  --weight: 900;
  /* border-radius: 24px; */
}
/* .linkCollapsible:active {
  border: none;
  background: var(--ClickState);
  --fill: 1;
  --weight: 900;
  transform: scale(0.98);
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.24);
} */
.link_text {
  white-space: nowrap;
  font-size: var(--font-regular);
  background-color: transparent;
}
.link_text_collapse {
  white-space: nowrap;
  font-size: var(--font-smallest);
  background-color: transparent;
}
.menu {
  display: flex;
  color: var(--fontColor);
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu :hover {
  --fill: 1;
  --weight: 900;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
  border-bottom: var(--white) 0.5px solid;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" var(--fill), "wght" var(--weight), "GRAD" 0,
    "opsz" 48;
}

.sidebarLowerCollapse {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.sidebarLower {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: flex-start;
  /* gap: 12px; */
  margin-bottom: 5px;
}

.sidebarLogo {
  margin: 15px;
}

@media screen and (min-height: 600px) {
  .sidebarLogo {
    margin-bottom: 50px;
  }
}

.sidebarBottom {
  margin-top: 5px;
  margin-bottom: 29px;
}

.profileImg {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  object-position: center;
}

.profileImgIcon {
  border-radius: 30px;
  width: 26px;
  height: 26px;
}

.sidebarLayer2 {
  min-width: 328px;
}

/* //Global Css */
.Sub-heading {
  font-weight: 600;
  font-size: 1.5rem;
  color: var(--fontColor);
}
.xtra-small {
  font-size: 14px;
  font-weight: 400;
  color: var(--fontColor);
}
.bold {
  font-weight: bold;
  color: var(--fontColor);
}
.cursor-pointer {
  cursor: pointer;
}
/* //Scrollbar Css */
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
/*
::-webkit-scrollbar-track {
  background: var(--BackgroundColor);
}
*/

/* Handle */
::-webkit-scrollbar-thumb {
  background: darkgrey;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input::placeholder {
  font-size: 13px;
}

.d-none {
  display: none !important;
}

.Card-image-w50-h50 {
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
}

.ellipsisStyling {
  /* creating horizontal scrollbar issue */
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 100%; */
}

.cursoreChangeAll {
  cursor: url("../public/Assets/MarkerWithPointer_xs.svg"), auto !important;
}

.popup {
  position: absolute;
  z-index: 100;
  background-color: #fff;
  padding: 5px 8px;
  font-size: 13px;
  box-shadow: 0px 0px 7px rgb(205 74 6 / 30%);
  border-radius: 4px;
}

.custom-upload-file-active {
  border-style: dashed;
  border-color: var(--primaryColor);
  z-index: 1;
  display: flex;
  flex-direction: column;
}
.custom-upload-file-active p,
.custom-upload-file-active svg {
  color: var(--primaryColor);
}
.tagsHorizontal > div > span {
  padding-bottom: 3px;
}
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
.img-contain {
  display: block;
  max-height: 500px;
  width: auto;
  height: auto;
  border-radius: 12px;
  object-fit: fill;
  object-position: left top;
  max-width: 100%;
}
.vertical-image {
  max-height: 500px;
  min-height: 500px;
}

.horizontal-image {
  width: 100%;
  max-height: 500px;
  /*min-height: 150px;*/
}
.icons-wrapper svg {
  width: 30px;
  height: 30px;
}
.icons-wrapper-small svg {
  width: 20px;
  height: 20px;
  margin-left: 6px;
  margin-bottom: 3px;
}
.circled-icon {
  background-color: var(--fontColor);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circled-icon svg {
  width: 15px;
  height: 15px;
  color: white;
}
.circled-icon-orange {
  background: var(--primaryColor);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 33px;
}

.circled-icon-orange svg {
  width: 20px;
  height: 20px;
  background: var(--primaryColor);
  color: white;
}
.circled-book-image img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

/* ______________________dropdown start_____________________ */

.dropdown {
  position: relative;
  color: #333;
  cursor: default;
}
.selected-value input {
  background: transparent !important;
  border: 0;
}

.custom-search .selected-value input {
  color: transparent;
}
.bookDetailsWrapper .selected-value input::placeholder {
  opacity: 0;
}

.dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.dropdown .selected-value input {
  line-height: 1.5;
  font-size: 1rem;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
}

.dropdown .options {
  display: none;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 275px;
  overflow-y: auto;
  position: absolute;
  top: 125%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
  display: block;
}

.dropdown .option {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.dropdown .option.selected {
  background-color: var(--selectedBackgroundColor);
  color: var(--fontDarkColor);
}

.dropdown .option:hover {
  background-color: var(--HoverOver);
  color: var(--fontDarkColor);
}

.socialButtonStyle {
  color: var(--socialIconColor);
  cursor: pointer;
}

.primaryColor {
  color: var(--primaryColor);
}

.socialButtonStyle.under-construction {
  display: none;
  color: var(--disableColor);
  cursor: default;
}

.textDarkWithBackgroundAndBorder {
  color: var(--fontDarkColor);
  background-color: var(--submenuBackgroundColor);
  border: 1px solid var(--borderColors);
}

.textDark {
  color: var(--fontDarkColor);
}

.textPrimary {
  color: var(--fontColor);
}

.selected {
  background-color: var(--selectedBackgroundColor);
}

.contentCentered {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
