.buttonStyleBig {
    margin-bottom: 6px;
    margin-right: 4px;
}

.buttonStyleBig.MuiSvgIcon-root {
    font-size: 20px;
}

.buttonStyle {
    margin-bottom: 4px;
    margin-right: 6px;
    font-size: 3px;
}

.buttonStyle.MuiSvgIcon-root {
    font-size: 16px;
}

.logoStyleBig {
    display: inline-block;
    margin-bottom: 6px;
    margin-right: 3px;
    height: 26px;
    width: 26px;
}

.logoStyle {
    display: inline-block;
    margin-bottom: 3px;
    margin-right: 4px;
    height: 20px;
    width: 20px;
}

.style {
    color: var(--fontLightColor);
}