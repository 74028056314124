.bolder-span {
  font-weight: bolder;
  margin-right: 5px;
}

.background-gradient-wrapper {
  position: relative;
  padding-right: 0px;
}

.chapterTitleBox {
  margin-top: 12px;
  padding-top: 12px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  position: relative;
}

.chapterTitleBoxClosed {
  padding-right: 25px;
  padding-top: 0px;
  margin-top: 0px;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: absolute;
}

.responsive-typography {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chapterContents {
  max-height: 40vh;
  margin-left: -5px;
}

@media screen and (min-width: 800px) {
  .chapterTitleBoxClosed {
    padding-right: 45px;
  }

  .chapterContents {
    margin-right: 15px;
  }
}

@media screen and (min-width: 1300px) {
  .chapterTitleBox {
    padding-right: 20px;
  }
  .chapterTitleBoxClosed {
    padding-right: 70px;
  }

  .chapterContents {
    margin-right: 35px;
  }
}

@media screen and (min-width: 2000px) {
  .chapterTitleBox {
    padding-right: 50px;
  }
  .chapterTitleBoxClosed {
    padding-right: 90px;
  }

  .chapterContents {
    margin-right: 50px;
  }
}
