/* ________________________________text color start_________________________________ */

.text-primary-dark {
  color: var(--white) !important;
}
.text-primary-light {
  color: var(--fontColor) !important;
}

/* ________________________________text color end_____________________________________ */

/* _____________________________background color start________________________________ */

/* filter dropdowns */
.background-filter-outer-dark {
  background-color: var(--backgroundBlackSecond) !important;
}
.background-filter-outer-light {
  background-color: var(--DropDownMenuBackgroundColor) !important;
}
.background-filter-heading-dark {
  background-color: var(--backgroundBlackThird) !important;
}
.background-filter-heading-light {
  background-color: var(--ClickState) !important;
}
/* radio use in filter inside sidebar */
.filter-radio [type="radio"]:checked + label.input-label-dark:after,
.filter-radio [type="radio"]:not(:checked) + label.input-label-dark:after {
  background: var(--white);
  border-color: var(--white);
}
.filter-radio [type="radio"]:checked + label.input-label-light:after,
.filter-radio [type="radio"]:not(:checked) + label.input-label-light:after {
  background: var(--fontColor);
  border-color: var(--fontColor);
}
/* checkbox used in filter inside sidbar */
.filter-checkbox.form-control label.input-label-light {
  border-color: var(--fontColor);
}

/* ____________________________background color end_________________________________ */

/* _____________________________shadow color start____________________________________ */

.background-shadow-dark {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 0.01%,
    var(--backgroundBlackSecond) 100%
  );
}
.background-shadow-light {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 0.01%,
    var(--white) 100%
  );
}
/* _____________________________shadow color end_______________________________________ */
