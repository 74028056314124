.overlay {
  position: fixed;
  top: 0;
  /* right: 0; */
  width: 100%;
  z-index: 20;
}
.overlay__background {
  /* background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer; */
}

.overlay__container {
  /* position: fixed;
  bottom: 0;
  margin: auto;
  padding: 1px 5px 2px 0px;
  width: 100%;
  height: 100%;
  z-index: 3; */
  padding: 1px 2px 0px 0px;

}

.overlay-container-dark {
  background-color: rgba(0, 0, 0, 1);
}

.overlay-container-light {
  background-color: rgba(255, 255, 255, 1);
}
