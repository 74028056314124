.switch_right {
  background-color: var(--ClickState);
}
.switch {
  position: relative;
}
.switch__input {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  z-index: 1;
}
.switch__label {
  display: block;
  position: relative;
  width: 80px;
  height: 30px;
  background-color: var(--ClickState);
  border-radius: 25px;
  transition: 0.4s;
}
.switch__label.disabled {
  background-color: var(--disableColor);
}
.switch__label span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.switch__label .switch_left {
  position: absolute;
  top: 0;
  right: auto;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  transition: 0.4s;
  background-color: var(--greyColor);
  color: var(--white);
}
.switch__label .switch_left.disabled {
  background-color: var(--disableColor);
}
.switch__label .switch_right.disabled {
  background-color: var(--disableColor);
}
.icon {
  color: var(--greyColor);
}
.switch__input:checked + .switch__label {
  background-color: var(--ClickState);
}
.switch__input:checked + .switch__label .switch_left {
  display: none;
}

.switch__input + .switch__label .switch_right {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.switch__input:checked + .switch__label .switch_right {
  position: absolute;
  top: 0;
  right: auto;
  bottom: 0;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: var(--greyColor);
  color: var(--white);
  left: calc(100% - 30px);
}
.mid-section .mobile {
  display: none;
}

.optionText {
  color: var(--fontColor);
}
.optionText.disabled {
  color: var(--disableColor);
}
.optionBorder {
  display: inline-block;
  border-color: var(--fontColor);
  border-bottom-width: 2px;
  font-weight: 700;
}
.optionBorder.disabled {
  border-color: var(--disableColor);
}

.profile-main-wrapper {
  background-color: var(--cardBackgroundColor);
}

@media screen and (max-width: 680px) {
  .profile-wrapper {
    flex-direction: column;
  }
  .profile-wrapper .right {
    margin-top: 50px;
  }
  .mid-section {
    margin-left: 0;
    margin-top: 50px;
  }
  .bottom-section {
    margin-top: 50px;
  }
  .profile-main-wrapper {
    padding-bottom: 60px;
  }
  .profile-wrapper input {
    width: 260px;
  }
  .mid-section {
    white-space: nowrap !important;
  }
}
@media screen and (max-width: 500px) {
  .profile-wrapper-inner.right {
    width: 100%;
  }
  .profile-wrapper-inner.right > div {
    margin: 0px;
    flex-direction: column;
    gap: 2px;
    margin-top: 15px;
    align-items: flex-start;
  }
  .profile-wrapper-inner.right {
    height: auto;
  }
  .profile-wrapper-inner.right > div > p {
    left: 0;
  }
  .profile-wrapper .profile-wrapper-inner.right input {
    width: 100%;
  }
  .mid-section {
    margin-top: 50px;
    align-items: center;
  }
  .mid-section table tr td {
    padding: 8px 0 8px 0;
  }
  .mid-section table tr td:nth-child(3) {
    display: none;
  }
  .mid-section table tr td:nth-child(1) {
    text-align: left;
  }
  .bottom-section {
    flex-direction: column;
    align-items: center;
  }
  .bottom-section > div:nth-child(2) {
    margin-top: 30px;
  }
  .mid-section .mobile {
    display: inline-block;
    width: 80px;
  }
  .mid-section .desktop {
    display: none;
  }
}
